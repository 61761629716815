// Gatsby supports TypeScript natively!
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Panel from '../components/panel';
import LinkOut from '../components/linkOut';
import TopPanel from '../components/topPanel';
import ButtonLink from '../components/buttonLink';

import HorizontalTitle from '../components/horizontalTitle';
import HorizontalButtonsContainer from '../components/horizontalButtonsContainer';
import VerticalButtonsContainer from '../components/verticalButtonsContainer';

import treyideker from '../images/treyideker.jpg';
import emmalundberg from '../images/emmalundberg.jpg';
import leahschaffer from '../images/leahschaffer.jpg';
import dexterpratt from '../images/dexterpratt.jpg';
import gegeqian from '../images/gegeqian.jpg';
import clarahu from '../images/clarahu.jpg';
import xikunz2 from '../images/xikunz2.jpg'
import ishangaur from '../images/ishangaur.jpg'
import trangle from '../images/trangle.jpg'
import anthonycesnik from '../images/anthonycesnik.jpg'
import chrischuras from '../images/chrischuras.png'
import mayankjain from '../images/mayankjain.jpg'
import xiaoyuzhao from '../images/xiaoyuzhao.jpg'
import ernstpulido from '../images/ernstpulido.jpg'
import willleineweber from '../images/willleineweber.jpg'
import fredbn from '../images/fredbn.jpeg'

import styles from './pageStyles/index.module.css';

const contrastBackgroundColor = 'rgba(65,182,255, 0.25)';
const contrastTextColor = 'black';

const TeamPage = (props: PageProps) => (
  <Layout>
    <SEO title='MuSIC Project Team' />
   
    <TopPanel>
      <HorizontalTitle title='MuSIC Project' subtitle='The MuSIC Team' />

    </TopPanel>

    <Panel
    >
      <div className={styles.logosContainer}>
      <div className={styles.logoGroup}>
        <img src={treyideker} style={{ width: '13.5vw', minWidth: '10em' }} />
       <h3>
        Trey Ideker, PhD,  <LinkOut link='http://idekerlab.ucsd.edu/'>Ideker Lab</LinkOut>
      </h3>
        </div>
      <div className={styles.logoGroup}>
        <img src={emmalundberg} style={{ width: '15vw', height: '20vw', minWidth: '10em' }} />
       <h3>
        Emma Lundberg, PhD, <LinkOut link='https://cellprofiling.org/'>Lundberg Lab</LinkOut>
      </h3>
        </div>
      </div>
      <div className={styles.logosContainer}>
      <div className={styles.logoGroup}>
        <img src={fredbn} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Frederic Ballllosera Navarro
      </h3>
        </div>
      <div className={styles.logoGroup}>
        <img src={chrischuras} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Christopher Churas
      </h3>
        </div>
      </div>
      <div className={styles.logosContainer}>
      <div className={styles.logoGroup}>
        <img src={anthonycesnik} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Anthony Cesnik, PhD
      </h3>
        </div>
      <div className={styles.logoGroup}>
        <img src={ishangaur} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Ishan Gaur
      </h3>
        </div>
      </div>
      <div className={styles.logosContainer}>
      <div className={styles.logoGroup}>
        <img src={clarahu} style={{ width: '16vw', minWidth: '10em' }} />
       <h3>
        Clara (Mengzhou) Hu
      </h3>
        </div>
      <div className={styles.logoGroup}>
        <img src={mayankjain} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Mayank Jain
      </h3>
        </div>
      </div>
      <div className={styles.logosContainer}>
      <div className={styles.logoGroup}>
        <img src={trangle} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Trang Le
      </h3>
        </div>
      <div className={styles.logoGroup}>
        <img src={willleineweber} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        William Leineweber, PhD
      </h3>
        </div>
      </div>
      <div className={styles.logosContainer}>
      <div className={styles.logoGroup}>
        <img src={dexterpratt} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Dexter Pratt
      </h3>
        </div>
      <div className={styles.logoGroup}>
        <img src={ernstpulido} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Ernst Pulido
      </h3>
        </div>
      </div>
      <div className={styles.logosContainer}>
      <div className={styles.logoGroup}>
        <img src={gegeqian} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Gege (Scarlett) Qian
      </h3>
        </div>
      <div className={styles.logoGroup}>
        <img src={leahschaffer} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Leah Schaffer, PhD
      </h3>
        </div>
      </div>
      <div className={styles.logosContainer}>
      <div className={styles.logoGroup}>
        <img src={xikunz2} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Xikun Zhang
      </h3>
        </div>
        <div className={styles.logoGroup}>
        <img src={xiaoyuzhao} style={{ width: '15vw', height: '15vw', minWidth: '10em' }} />
       <h3>
        Xiaoyu Zhao, PhD
      </h3>
        </div>
      </div>
    </Panel>
  </Layout>
);

export default TeamPage;
